/* Chat.css */
.chat-container {
  display: flex;
  height: 100vh;
  max-width: 100%;
  margin: 0;
  background-color: #f7f7f7;
}

.chat-list {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  border-right: 1px solid #ddd;
}

.chat-session-container {
  flex: 3;
  overflow-y: auto;
  padding: 20px;
}

.chat-session {
  margin-bottom: 10px;
}

.chat-session button {
  width: 100%;
  text-align: left;
  padding: 10px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  cursor: pointer;
}

.chat-session button:hover {
  background-color: #e2e6ea;
}

.message-list {
  height: 400px; /* Ajustable */
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 20px;
}

.message {
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 4px;
}

.sent {
  text-align: right;
  background-color: #dcf8c6;
}

.received {
  text-align: left;
  background-color: #ebebeb;
}

.message input, .message button {
  width: 100%;
}

.message input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.message button {
  background-color: #007bff;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 4px;
}
